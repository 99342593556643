import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const OurTeamPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "our-team" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					ourTeamPageFields {
						introSec {
							heading
							description
							video1Option
							uploadVideo {
								node {
									mediaItemUrl
								}
							}
							youtubeVideo
							video2Option
							uploadVideo2 {
								node {
									mediaItemUrl
								}
							}
							youtubeVideo2
						}
						ourTeamSection {
							heading
							teamMember {
								nodes {
									... on WpOurTeam {
										slug
										teamMemberFields {
											firstName
											lastName
											order
											description
											image {
												node {
													altText
													localFile {
														childImageSharp {
															gatsbyImageData(
																formats: [AUTO, WEBP]
																quality: 100
																transformOptions: {
																	cropFocus: CENTER
																	fit: CONTAIN
																}
																layout: CONSTRAINED
																placeholder: BLURRED
															)
															original {
																width
																height
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			ourTeamPageFields: { introSec, ourTeamSection },
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Our Team",
				item: {
					url: `${siteUrl}/our-team`,
					id: `${siteUrl}/our-team`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/our-team`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Our Holding Space Team",
						},
					],
				}}
			/>

			{introSec && !checkPropertiesForNull(introSec, ["heading"]) && (
				<section className="py-5">
					<Container>
						<Row>
							<Col>
								<h1 className="text-primary">{introSec?.heading}</h1>
								<SafeHtmlParser htmlContent={introSec?.description} />
							</Col>
						</Row>
					</Container>
				</section>
			)}
			{introSec && !checkPropertiesForNull(introSec, ["video1Option"]) && (
				<section>
					<Container>
						<Row>
							{introSec?.video1Option && (
								<Col className="pb-5" lg={6}>
									{introSec?.video1Option === "YouTube" ? (
										<iframe
											loading="lazy"
											width="100%"
											height="400"
											src={introSec?.youtubeVideo}
											title="The Vision | Holding Space Eastbourne"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									) : (
										<video style={{ width: "100%" }} controls>
											<source
												src={introSec?.uploadVideo.node?.mediaItemUrl}
												type="video/mp4"
											/>
											<p>
												Video showing how the Invisalign aligner process works
											</p>
										</video>
									)}
								</Col>
							)}
							{introSec?.video2Option && (
								<Col className="pb-5" lg={6}>
									{introSec?.video2Option === "YouTube" ? (
										<iframe
											loading="lazy"
											width="100%"
											height="400"
											src={introSec?.youtubeVideo2}
											title="The Vision | Holding Space Eastbourne"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									) : (
										<video style={{ width: "100%" }} controls>
											<source
												src={introSec?.uploadVideo2.node?.mediaItemUrl}
												type="video/mp4"
											/>
											<p>
												Video showing how the Invisalign aligner process works
											</p>
										</video>
									)}
								</Col>
							)}
						</Row>
					</Container>
				</section>
			)}
			{ourTeamSection && ourTeamSection.length > 0 && (
				<>
					{ourTeamSection.map((item, i) => (
						<div key={uuid4()}>
							{i === 0 ? (
								<section className="pb-5">
									<Container>
										<Row>
											<Col>
												<h2 className="text-primary">{item?.heading}</h2>
											</Col>
										</Row>
										{item.teamMember &&
											item.teamMember.nodes &&
											item.teamMember.nodes.length > 0 && (
												<>
													{item.teamMember.nodes.map((teamItem) => (
														<div key={uuid4()}>
															<p className="text-primary">
																{teamItem?.teamMemberFields?.firstName}{" "}
																{teamItem?.teamMemberFields?.lastName}
															</p>
															<Row>
																<Col className="text-center">
																	<GatsbyImage
																		image={
																			teamItem.teamMemberFields?.image.node
																				?.localFile.childImageSharp
																				.gatsbyImageData
																		}
																		className="float-sm-left mr-md-4"
																		alt={
																			teamItem.teamMemberFields?.image.node
																				?.altText
																		}
																		style={{ maxWidth: "200px" }}
																	/>
																	<div className="text-left">
																		<SafeHtmlParser
																			htmlContent={
																				teamItem.teamMemberFields?.description
																			}
																		/>
																	</div>
																</Col>
															</Row>
														</div>
													))}
												</>
											)}
									</Container>
								</section>
							) : (
								<section className="pb-5">
									<Container>
										<Row className="pb-3">
											<Col>
												<h2 className="text-primary">{item?.heading}</h2>
											</Col>
										</Row>
										<Row>
											{item.teamMember &&
												item.teamMember.nodes &&
												item.teamMember.nodes.length > 0 && (
													<>
														{item.teamMember.nodes.map((teamItem) => (
															<Col
																xs={12}
																md={6}
																lg={3}
																className="text-center"
															>
																{teamItem.teamMemberFields.description ? (
																	<Link to={`/our-team/${teamItem.slug}`}>
																		<GatsbyImage
																			image={
																				teamItem.teamMemberFields?.image.node
																					?.localFile.childImageSharp
																					.gatsbyImageData
																			}
																			alt={
																				teamItem.teamMemberFields?.image.node
																					?.altText
																			}
																			height={200}
																			width={200}
																		/>
																		<h6 className="text-primary text-center">
																			{teamItem?.teamMemberFields?.firstName}{" "}
																			{teamItem?.teamMemberFields?.lastName}
																		</h6>
																	</Link>
																) : (
																	<>
																		<GatsbyImage
																			image={
																				teamItem.teamMemberFields?.image.node
																					?.localFile.childImageSharp
																					.gatsbyImageData
																			}
																			alt={
																				teamItem.teamMemberFields?.image.node
																					?.altText
																			}
																			height={200}
																			width={200}
																		/>
																		<h6 className="text-primary text-center">
																			{teamItem?.teamMemberFields?.firstName}{" "}
																			{teamItem?.teamMemberFields?.lastName}
																		</h6>
																	</>
																)}
															</Col>
														))}
													</>
												)}
										</Row>
									</Container>
								</section>
							)}
						</div>
					))}
				</>
			)}
		</Layout>
	);
};
export default OurTeamPage;
